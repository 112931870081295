









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.menubox /deep/ .el-table__row:hover {
  cursor: pointer;
}

.menubox {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.menu {
  transition: all 0.3s;
  width: 3rem;
  height: 100%;
  background-color: #324767;
  box-shadow: 1px 0 8px 0 #b7b7b9;
  position: relative;
  z-index: 2;
  color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow: initial;

  .seat {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    overflow: hidden;
    align-items: center;

    .img-box {
      height: 1.5625rem;
      width: 1.5625rem;
    }
  }
}

.menu-cur {
  width: 13rem;
}

.menu .seat .header {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header-title {
  height: 6rem;
  background-color: #324767;
  box-shadow: 0 4px 4px 0 #293c59;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  .seat {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;

    img {
      width: 1.75rem;
    }
  }
}

h4 {
  flex: 1;
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 0.925rem;
  box-sizing: content-box;
}

.nav-title {
  padding-top: 0.25rem;
}

.nav-title .seat {
  font-size: 0;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.nav-title .seat:hover {
  background-color: #1d2d47;
}

.nav {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  overflow: hidden;
}

.nav .nav-box {
  height: 100%;
  width: calc(100% + 17px);
  overflow-y: auto;
}

.nav .nav-box>ul {
  width: 100%;
  padding: 0;
  // padding-right: 17px;
  list-style: none;
}

.nav .nav-box>ul a {
  color: #fff;
  width: 100%;
  display: flex;
  overflow: hidden;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.nav .nav-box>ul>li> {
  padding: 0.5rem 0;
}

.nav .nav-box>ul>li>.seat {
  padding: 0.75rem 0;
  font-size: 1rem;
  border-bottom: 1px solid transparent;
}

.nav .nav-box>ul>li>.seat .img-box {
  padding-left: 0.75rem;
  box-sizing: content-box;
}

.nav .nav-box>ul>li>.seat span {
  padding-left: 0.75rem;
  box-sizing: content-box;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.Informationbox {
  flex: 1;
  padding-top: 3rem;
  height: 100%;
  overflow: hidden;

  section {
    height: 100%;
  }
}

.control {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}

.control .header {
  height: 3rem;
  position: relative;
  box-shadow: 2px 2px 12px 0 #eeeeee;
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: initial;
  // justify-content: flex-end;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;

  .topAdvertisement {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    flex: 1;

    img {
      vertical-align: middle;
      width: 17px;
    }

    span {
      color: #f56c6c;
      font-weight: 500;
      cursor: pointer;
      border-radius: 30px;
      border: 1px solid #f56c6c;
      padding: 4px 8px;
      margin-left: 20px;
      transition: 0.3s;

      &:hover {
        color: #fff;
        background: #f56c6c;
      }
    }
  }
}

.headr-btn-title .img-box {
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }
}

.control .header .headerBtn {
  padding-right: 0.5rem;

  .seat {
    &>div {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &:first-child .headr-btn-title {
        border-left: none;
      }

      .headr-btn-title {
        min-width: 3.9375rem;
        text-align: center;
        border-left: 1px solid #eaeaeb;
        white-space: nowrap;
        padding: 0 0.84375rem;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          vertical-align: middle;
          white-space: nowrap;
          font-size: 0.875rem;
          line-height: normal;
          color: #8c8b8b;
        }

        .img-box {
          display: flex;
          align-items: center;
        }
      }

      &.headr-btn-out .headr-btn-title {
        border: 0;
      }

      &.headr-btn-title-border {
        border-right: 1px solid #eaeaeb;
        border-left: 1px solid #eaeaeb;
      }
    }
  }
}

.menuLabel {
  display: flex;
  flex-direction: column;
}

.videoMation {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;

  >span {
    width: 50%;
    padding: 5px 0;
    display: flex;
    align-items: center;

    >span {
      width: 120px;
    }

    div {
      margin-left: 10px;
      max-width: 27rem;
    }
  }
}

.headr-btn-title /deep/.el-badge {
  padding: 0 !important;
}

.menubox /deep/ .el-badge__content {
  top: -3px;
  right: 20px;
  height: 16px;
  line-height: 16.5px;
}

.Informationboxs {
  height: 100%;
}

.mongoliaStyle {
  background-image: url("../assets/loginInnerMongolia/login_top.png");
  color: white;

  span {
    color: white !important;
  }
}


/*课时换算*/
.conversion {
  /deep/.el-dialog {
    border-radius: 12px;
  }

  /deep/.el-dialog__header {
    padding-top: 30px !important;
    padding-bottom: 0 !important;
    background-color: transparent;

    .el-dialog__title {
      color: #333;
      font-weight: bold;
      font-size: 17px;
    }

    .el-icon-close:before {
      color: #333;
      border: 0;
    }

    .el-dialog__headerbtn {
      top: 30px;
    }
  }

  /deep/.el-dialog__body {
    padding: 14px 40px 0 !important;
  }

  /deep/.el-dialog__footer {
    padding-bottom: 30px !important;
  }

  /deep/.el-form-item:last-child {
    .el-form-item__label {
      color: #2878ff;
    }
  }

  /deep/.el-form-item__label,
  /deep/.el-form-item__content {
    line-height: 30px !important;

  }

  /deep/.el-form-item__content {
    font-size: 12px !important;
  }

  /deep/.el-dialog__footer {
    padding-top: 10px;
  }

  /deep/.dialog-footer {
    margin-top: 0;

    .bgc-bv {
      background-color: #2878ff !important;
      color: #fff !important;
      border-color: #2878ff !important;
    }
  }

  /deep/.el-form--label-left .el-form-item__label {
    font-weight: bold;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    padding: 0 4px !important;
  }

  /deep/.el-input.is-active .el-input__inner,
  /deep/.el-input__inner:focus {
    border-color: #409EFF;
  }

  /deep/.el-icon-arrow-up {
    color: #2878ff;
  }

  .resultBg {
    display: inline-block;
    width: 100%;
    background-color: rgba(40, 120, 255, 0.10);
    border-radius: 2px;
    padding: 0 8px;
    text-align: right;

    span {
      font-weight: bold;
      font-size: 14px;
      color: #2878ff;
      padding: 0 4px;
      cursor: pointer
    }
  }

  .conversionItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .conversionInner {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        margin-right: 0;
      }

      span {
        margin-left: 4px;
        min-width: 16px;
      }
    }

    &.type-1 {
      .conversionInner {
        width: calc(33% - 6px);

        /deep/.el-input-number {
          .el-input {
            width: 46px !important;
          }
        }
      }
    }

    &.type-2 {
      .conversionInner {
        width: 100%;

        /deep/.el-input-number--mini {
          width: 100% !important;
        }

        /deep/.el-input-number {
          .el-input {
            width: 100% !important;
          }
        }
      }
    }

    &.type-3 {
      .conversionInner {
        width: 50%;

        /deep/.el-select {
          &:nth-child(1) {
            .el-input {
              width: 100% !important;
            }
          }

          &:nth-child(2) {
            .el-input {
              width: 80px !important;
            }
          }

        }
      }
    }
  }
}
