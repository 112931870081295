
























































































































































































































.download-notify {
  z-index: 4;
  background-color: #fff;
  transition: background-color 0.3s;
  .hiddenBtn {
    position: absolute;
    top: 22px;
    right: 40px;
    cursor: pointer;
    color: #909399;
    font-size: 16px;
  }
  .position_custom {
    top: 22px;
  }
}
.el-notification {
  max-height: 600px;
  overflow-y: scroll;
}
.el-notification__title {
  line-height: 16px;
  font-size: 16px;
  color: #333;
}
.download-notify-highlight {
  background-color: #f4ff00;
}

.el-notification__icon_custom {
  width: 20px;
  height: 20px;
  margin-right: 9px;
}
.list_round {
  width: 6px;
  height: 6px;
  position: relative;
  top: 4px;
  left: 7px;
  margin-right: 17px;
}
