.download-zoom-width .el-badge__content.is-fixed {
  top: 3px;
  right: -4px;
}
.success_color {
  color: #00bd16;
  font-size: 14px;
  line-height: 14px;
}
.handle_color {
  color: #fea10f;
  font-size: 14px;
  line-height: 14px;
}
.load_fail_btn {
  background-color: #F2F7FD;
  line-height: 14px;
  font-size: 14px;
  display: block;
  padding: 5px 10px;
  color: #5B6BE7;
  border-radius: 4px;
}
.load_fail_btn .el-link {
  display: block;
  line-height: 30px;
}
