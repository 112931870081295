



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.bjInfo2 {
  background: red;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
  vertical-align: middle;
  margin-top: -3px;
}

.menubox {
  .el-menu {
    border: none;
  }

  .el-menu-item {
    display: flex;
    align-items: center;

    &.is-active {
      background-color: #182943 !important;
      color: #fff;
    }
  }

  .el-submenu {
    &.is-opened .el-submenu__title {
      background-color: rgb(34, 48, 71) !important;
    }

    .el-submenu__title {
      padding: 0 !important;
      height: 40px;
      line-height: 40px;

      img {
        padding-left: 0.75rem;
      }

      span {
        padding-left: 0.75rem;
      }
    }

    .el-menu {
      .el-menu-item {
        padding-left: 55px !important;
        height: 40px;
        line-height: 40px;

        &.is-active {
          background-color: #182943 !important;
        }
      }
    }
  }

  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "" !important;
    }
  }
}

#app~.el-menu--vertical {
  .el-menu-item-group__title {
    padding: 0;
  }

  a {
    color: #fff;
  }

  .is-active {
    background-color: #1d2d47 !important;
  }
}
