











































































































































.topbar{
    height: 3rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
    overflow: hidden;
    padding: 0 1.875rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    // 左侧
    .left{
        display: flex;
        height: 100%;
        align-items: center;
        flex-shrink: 0;
        .img-wrap{
            width: 2.9375rem;
            height: 1.5rem;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .line{
            width: 1px;
            height: 1rem;
            background: #999999;
            margin: 0 .625rem;
        }
        .title{
            font-size: .875rem;
            font-weight: 600;
            color: #333333;
            margin-right: .625rem;
            line-height: .875rem;
        }
        .version{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3.25rem;
            height: 1.25rem;
            background: #2877ff2d;
            border-radius: 10px;
            font-size: .75rem;
            color: #2878FF;
        }
    }
    // 右侧
    .right{
        display: flex;
        height: 100%;
        align-items: center;
        flex-shrink: 0;
        margin-left: 50px;
        .img-wrap{
            width: .9375rem;
            height: 1rem;
            img{
                display: block;
                height: 100%;
                width: 100%;
            }
        }
        .what{
            margin-left: 5px;
            width: 14px;
            height: 14px;
            cursor: pointer;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .compName{
            font-size: .875rem;
            color: #666666;
            line-height: .875rem;
        }
        .mobile{
            font-size: .875rem;
            color: #333333;
            line-height: .875rem;
        }
        .line{
            width: 1px;
            height: 1rem;
            background: #999999;
            margin: 0 .625rem;
        }
        .auth{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3.25rem;
            height: 1.25rem;
            border-radius: 10px;
            font-size: .75rem;
            margin-left: 4px;
            cursor: pointer;
        }
        .el-dropdown{
            margin-left: .625rem;
            .down{
                display: block;
                width: 1rem;
                height: .5rem;
            }
        }
    }
}
