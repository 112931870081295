.menubox /deep/ .el-table__row:hover {
  cursor: pointer;
}
.menubox {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.menu {
  transition: all 0.3s;
  width: 3rem;
  height: 100%;
  background-color: #324767;
  box-shadow: 1px 0 8px 0 #b7b7b9;
  position: relative;
  z-index: 2;
  color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  overflow: initial;
}
.menu .seat {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  align-items: center;
}
.menu .seat .img-box {
  height: 1.5625rem;
  width: 1.5625rem;
}
.menu-cur {
  width: 13rem;
}
.menu .seat .header {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.header-title {
  height: 6rem;
  background-color: #324767;
  box-shadow: 0 4px 4px 0 #293c59;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-title .seat {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}
.header-title .seat img {
  width: 1.75rem;
}
h4 {
  flex: 1;
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 0.925rem;
  box-sizing: content-box;
}
.nav-title {
  padding-top: 0.25rem;
}
.nav-title .seat {
  font-size: 0;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}
.nav-title .seat:hover {
  background-color: #1d2d47;
}
.nav {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  overflow: hidden;
}
.nav .nav-box {
  height: 100%;
  width: calc(100% + 17px);
  overflow-y: auto;
}
.nav .nav-box > ul {
  width: 100%;
  padding: 0;
  list-style: none;
}
.nav .nav-box > ul a {
  color: #fff;
  width: 100%;
  display: flex;
  overflow: hidden;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}
.nav .nav-box > ul > li {
  padding: 0.5rem 0;
}
.nav .nav-box > ul > li > .seat {
  padding: 0.75rem 0;
  font-size: 1rem;
  border-bottom: 1px solid transparent;
}
.nav .nav-box > ul > li > .seat .img-box {
  padding-left: 0.75rem;
  box-sizing: content-box;
}
.nav .nav-box > ul > li > .seat span {
  padding-left: 0.75rem;
  box-sizing: content-box;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.Informationbox {
  flex: 1;
  padding-top: 3rem;
  height: 100%;
  overflow: hidden;
}
.Informationbox section {
  height: 100%;
}
.control {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}
.control .header {
  height: 3rem;
  position: relative;
  box-shadow: 2px 2px 12px 0 #eeeeee;
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: initial;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
}
.control .header .topAdvertisement {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  flex: 1;
}
.control .header .topAdvertisement img {
  vertical-align: middle;
  width: 17px;
}
.control .header .topAdvertisement span {
  color: #f56c6c;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid #f56c6c;
  padding: 4px 8px;
  margin-left: 20px;
  transition: 0.3s;
}
.control .header .topAdvertisement span:hover {
  color: #fff;
  background: #f56c6c;
}
.headr-btn-title .img-box {
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
  border-radius: 50%;
}
.headr-btn-title .img-box img {
  width: 100%;
  height: 100%;
}
.control .header .headerBtn {
  padding-right: 0.5rem;
}
.control .header .headerBtn .seat > div {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.control .header .headerBtn .seat > div:first-child .headr-btn-title {
  border-left: none;
}
.control .header .headerBtn .seat > div .headr-btn-title {
  min-width: 3.9375rem;
  text-align: center;
  border-left: 1px solid #eaeaeb;
  white-space: nowrap;
  padding: 0 0.84375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.control .header .headerBtn .seat > div .headr-btn-title span {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: normal;
  color: #8c8b8b;
}
.control .header .headerBtn .seat > div .headr-btn-title .img-box {
  display: flex;
  align-items: center;
}
.control .header .headerBtn .seat > div.headr-btn-out .headr-btn-title {
  border: 0;
}
.control .header .headerBtn .seat > div.headr-btn-title-border {
  border-right: 1px solid #eaeaeb;
  border-left: 1px solid #eaeaeb;
}
.menuLabel {
  display: flex;
  flex-direction: column;
}
.videoMation {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
}
.videoMation > span {
  width: 50%;
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.videoMation > span > span {
  width: 120px;
}
.videoMation > span div {
  margin-left: 10px;
  max-width: 27rem;
}
.headr-btn-title /deep/ .el-badge {
  padding: 0 !important;
}
.menubox /deep/ .el-badge__content {
  top: -3px;
  right: 20px;
  height: 16px;
  line-height: 16.5px;
}
.Informationboxs {
  height: 100%;
}
.mongoliaStyle {
  background-image: url("../assets/loginInnerMongolia/login_top.png");
  color: white;
}
.mongoliaStyle span {
  color: white !important;
}
/*课时换算*/
.conversion /deep/ .el-dialog {
  border-radius: 12px;
}
.conversion /deep/ .el-dialog__header {
  padding-top: 30px !important;
  padding-bottom: 0 !important;
  background-color: transparent;
}
.conversion /deep/ .el-dialog__header .el-dialog__title {
  color: #333;
  font-weight: bold;
  font-size: 17px;
}
.conversion /deep/ .el-dialog__header .el-icon-close:before {
  color: #333;
  border: 0;
}
.conversion /deep/ .el-dialog__header .el-dialog__headerbtn {
  top: 30px;
}
.conversion /deep/ .el-dialog__body {
  padding: 14px 40px 0 !important;
}
.conversion /deep/ .el-dialog__footer {
  padding-bottom: 30px !important;
}
.conversion /deep/ .el-form-item:last-child .el-form-item__label {
  color: #2878ff;
}
.conversion /deep/ .el-form-item__label,
.conversion /deep/ .el-form-item__content {
  line-height: 30px !important;
}
.conversion /deep/ .el-form-item__content {
  font-size: 12px !important;
}
.conversion /deep/ .el-dialog__footer {
  padding-top: 10px;
}
.conversion /deep/ .dialog-footer {
  margin-top: 0;
}
.conversion /deep/ .dialog-footer .bgc-bv {
  background-color: #2878ff !important;
  color: #fff !important;
  border-color: #2878ff !important;
}
.conversion /deep/ .el-form--label-left .el-form-item__label {
  font-weight: bold;
  font-size: 14px;
}
.conversion /deep/ .el-input__inner {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  padding: 0 4px !important;
}
.conversion /deep/ .el-input.is-active .el-input__inner,
.conversion /deep/ .el-input__inner:focus {
  border-color: #409EFF;
}
.conversion /deep/ .el-icon-arrow-up {
  color: #2878ff;
}
.conversion .resultBg {
  display: inline-block;
  width: 100%;
  background-color: rgba(40, 120, 255, 0.1);
  border-radius: 2px;
  padding: 0 8px;
  text-align: right;
}
.conversion .resultBg span {
  font-weight: bold;
  font-size: 14px;
  color: #2878ff;
  padding: 0 4px;
  cursor: pointer;
}
.conversion .conversionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.conversion .conversionItem .conversionInner {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.conversion .conversionItem .conversionInner:last-child {
  margin-right: 0;
}
.conversion .conversionItem .conversionInner span {
  margin-left: 4px;
  min-width: 16px;
}
.conversion .conversionItem.type-1 .conversionInner {
  width: calc(33% - 6px);
}
.conversion .conversionItem.type-1 .conversionInner /deep/ .el-input-number .el-input {
  width: 46px !important;
}
.conversion .conversionItem.type-2 .conversionInner {
  width: 100%;
}
.conversion .conversionItem.type-2 .conversionInner /deep/ .el-input-number--mini {
  width: 100% !important;
}
.conversion .conversionItem.type-2 .conversionInner /deep/ .el-input-number .el-input {
  width: 100% !important;
}
.conversion .conversionItem.type-3 .conversionInner {
  width: 50%;
}
.conversion .conversionItem.type-3 .conversionInner /deep/ .el-select:nth-child(1) .el-input {
  width: 100% !important;
}
.conversion .conversionItem.type-3 .conversionInner /deep/ .el-select:nth-child(2) .el-input {
  width: 80px !important;
}
